import React, { Component } from 'react';

import locations from './locations';
import AnimLink from '../shared/AnimLink';
import './Navigation.scss';

class Navigation extends Component {
  renderLink(loc) {
    return (
      <li key={loc.path}>
        <AnimLink
          exact
          to={loc.path}
          text={loc.label}
          activeClassName="active"
        />
      </li>
    );
  }

  render() {
    return (
      <nav id="navigation" className="navigation">
        <ul className="navigation__list" id={location.pathname}>
          {locations.map(this.renderLink)}
        </ul>
      </nav>
    );
  }
}

export default Navigation;
