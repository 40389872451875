import React, { PureComponent } from 'react';
import { NavLink } from 'react-router-dom';
import { Button } from 'veratti-ui';

import './AnimLink.scss';

export default props => {
  const { to, text, exact } = props;
  return (
    <NavLink exact to={to} activeClassName="active">
      <Button>{text}</Button>
    </NavLink>
  );
};
