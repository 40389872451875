import React, { Component } from 'react';
import classnames from 'classnames';
import './Home.scss';

import MouseSVG from '../assets/svg/mouse';
import DesignSVG from '../assets/svg/design';
import CodeSVG from '../assets/svg/code';
import DeploySVG from '../assets/svg/deploy';
import WebSVG from '../assets/svg/web';

class Home extends Component {
  state = { activeIndex: 0 };

  tileIcons = [DesignSVG, CodeSVG, DeploySVG, WebSVG];
  tileDescriptions = [
    'Unique website design created for your vision',
    'Entirely custom codebase that can be made to fit all of your needs',
    'Hosting solutions provided free for static sites',
    'Your site is one-of-a-kind, made to delight and retain your visitors'
  ];

  mouseLeave = e => {
    this.setState({ activeIndex: 0 });
  };

  renderIcon(activeIndex) {
    const SVG = this.tileIcons[activeIndex - 1] || MouseSVG;
    return SVG && <SVG />;
  }

  renderBubbleBackground() {
    return (
      <div className="home__bubble-background">
        <div />
        <div />
        <div />
        <div />
      </div>
    );
  }

  renderTile(text, index) {
    const onEnter = () => this.setState({ activeIndex: index });
    return (
      <div className="tile" onMouseEnter={onEnter} onClick={onEnter}>
        <div className="tile__swap">
          <div className="tile__name">
            {this.renderIcon(index)}
            {text}
          </div>
          <div className="tile__description">
            {this.tileDescriptions[index - 1]}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { activeIndex } = this.state;

    const bubbleClassNames = classnames(
      'home__bubble',
      `home__bubble--${activeIndex || 0}`
    );

    return (
      <div className="home">
        <h2>
          Full service <span className="emphasis">modern</span> website creation
        </h2>
        <h3 className="paragraph subtle">
          The touch & care of a craftsman - the delivery of a professional
        </h3>
        <div className="separator" />
        <div className="home__display" onMouseLeave={this.mouseLeave}>
          <div className="home__tiles">
            {this.renderTile('Design', 1)}
            {this.renderTile('Develop', 2)}
            {this.renderTile('Deploy', 3)}
            {this.renderTile('Dominate', 4)}
          </div>
          <div className={bubbleClassNames} aria-hidden="true">
            {this.renderBubbleBackground()}
            <div className="home__step">{activeIndex || ''}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
