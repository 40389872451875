import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeContext } from 'veratti-ui';

import Background from './background/Background';
import Header from './header/Header';
import Routes from './routes/Routes';

import './App.scss';

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div id="app">
          <Background />
          <Header />
          <Routes />
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
