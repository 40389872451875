import React, { Component } from 'react';
import './About.scss';

import DesignSVG from '../assets/svg/design';
import CodeSVG from '../assets/svg/code';
import PiggySVG from '../assets/svg/piggy';
import AnimLink from '../shared/AnimLink';

class About extends Component {
  advantages = [
    {
      svg: <DesignSVG />,
      text:
        "Your design isn't a cookie-cutter template. It's a unique collaboration between your ideas and my development experience."
    },
    {
      svg: <CodeSVG />,
      text:
        'Your website is made from-scratch with HTML, JavaScript, and CSS. Every pixel is under your command, and I invite ambitious ideas.'
    },
    {
      svg: <PiggySVG />,
      text:
        'My work is affordable because I am a team of one. I am fast, flexible, and ready to collaborate without costing you a fortune.'
    }
  ];

  renderTile({ svg, text }) {
    return (
      <li key={text} className="about__advantage">
        <div>
          <div className="about__icon">{svg}</div>
          <div className="about__text">{text}</div>
        </div>
      </li>
    );
  }

  renderLinkedIn() {
    return (
      <div className="about__tooltip">
        I began my web development journey in August of 2013.
      </div>
    );
  }

  render() {
    const advantageTiles = this.advantages.map(this.renderTile);

    return (
      <div className="about">
        <div className="about__general">
          <h2>Tatiana Veratti</h2>
          <div className="paragraph">
            I am an experienced professional web developer with a passion for
            creating. Building websites from scratch is a fulfilling and
            challenging process that I am always seeking more opportunities in.
            I created this LLC with the dream to build software that delights
            people and meets clients' needs.
          </div>
          <div className="separator" />
          <div className="separator" />
          <h2>Website Development</h2>
          <div className="paragraph">
            When you need a website for personal or commercial endeavors, you'll
            be looking for a developer that delivers a unique, smooth, and
            modern experience for your visitors. Rarely, however, is getting a{' '}
            <span className="emphasis">
              fully custom, from-scratch, modern web application
            </span>{' '}
            possible on a reasonable budget...
          </div>
          <div className="separator" />
          <h3 className="centered about__name">
            This is where you will find the advantages of working with{' '}
            <span className="no-wrap">
              <span className="fancy">Veratti</span> Web Development:
            </span>
          </h3>
          <ul className="about__advantages">{advantageTiles}</ul>
          <div className="separator" />
          <div className="paragraph centered">
            Ready to get this project started together?{' '}
            <AnimLink to="/contact" text="Get in touch!" />
          </div>
        </div>
      </div>
    );
  }
}

export default About;
