import React from 'react';
export default () => (
  <svg x="0" y="0" height="100%" width="100%" viewBox="0 0 480 480">
    <path
      d="M472,312h-16V104c0-4.418-3.582-8-8-8H64V56c0-4.418-3.582-8-8-8H32C14.327,48,0,62.327,0,80
        v320c0,17.673,14.327,32,32,32h416c4.418,0,8-3.582,8-8v-32h16c4.418,0,8-3.582,8-8v-64C480,315.582,476.418,312,472,312z M16,80
        c0-8.837,7.163-16,16-16h16v304H32c-5.619-0.009-11.141,1.474-16,4.296V80z M440,416H32c-8.837,0-16-7.163-16-16s7.163-16,16-16h24
        c4.418,0,8-3.582,8-8V112h376v200H296c-0.08,0-0.152,0.056-0.232,0.056c-1.159,0.021-2.3,0.294-3.344,0.8l-64,32
        c-3.954,1.971-5.562,6.774-3.591,10.729c0.775,1.556,2.036,2.816,3.591,3.591l64,32c1.112,0.548,2.336,0.83,3.576,0.824h144V416z
        M416,376h-16v-48h16V376z M304,376v-16h80v16H304z M384,344h-80v-16h80V344z M288,371.056L249.888,352L288,332.944V371.056z
        M464,376h-32v-48h32V376z"
    />
    <path
      d="M96,288h48c4.418,0,8-3.582,8-8v-16h24v16c0,4.418,3.582,8,8,8h80c4.418,0,8-3.582,8-8v-48
        c0-4.418-3.582-8-8-8h-80c-4.418,0-8,3.582-8,8v16h-24v-16c0-4.418-3.582-8-8-8h-16v-24h16c4.418,0,8-3.582,8-8v-16h24v16
        c0,4.418,3.582,8,8,8h80c4.418,0,8-3.582,8-8v-48c0-4.418-3.582-8-8-8h-80c-4.418,0-8,3.582-8,8v16h-24v-16c0-4.418-3.582-8-8-8H96
        c-4.418,0-8,3.582-8,8v48c0,4.418,3.582,8,8,8h16v24H96c-4.418,0-8,3.582-8,8v48C88,284.418,91.582,288,96,288z M192,240h64v32h-64
        V240z M192,152h64v32h-64V152z M104,152h32v32h-32V152z M104,240h32v32h-32V240z"
    />

    <path
      d="M408,248h-16v-8.88c21.645-22.091,21.283-57.547-0.808-79.192s-57.547-21.283-79.192,0.808
        c-21.333,21.773-21.333,56.61,0,78.384V248h-16v16h112V248z M325.344,229.744c-16.471-14.722-17.889-40.009-3.168-56.48
        c14.722-16.471,40.009-17.889,56.48-3.168c16.471,14.722,17.889,40.009,3.168,56.48c-0.996,1.114-2.053,2.172-3.168,3.168
        c-1.69,1.517-2.656,3.681-2.656,5.952V248h-16v-20.688l21.656-21.656l-11.312-11.312L352,212.688l-18.344-18.344l-11.312,11.312
        L344,227.312V248h-16v-12.296C328.002,233.43,327.036,231.263,325.344,229.744L325.344,229.744z"
    />

    <path d="M88,344h112v16H88V344z" />

    <path d="M88,376h112v16H88V376z" />

    <path d="M120,312h80v16h-80V312z" />

    <path d="M88,312h16v16H88V312z" />
  </svg>
);
