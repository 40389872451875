import React, { PureComponent } from 'react';
import { Button, Field } from 'veratti-ui';
import classNames from 'classnames';
import * as emailjs from 'emailjs-com';

import './Contact.scss';
import SendSVG from '../assets/svg/send';
import AnimLink from '../shared/AnimLink';

export default class extends PureComponent {
  state = { sent: false };

  sendForm = e => {
    e.preventDefault();

    const { email, request } = this.state;
    if (!email || !request) {
      this.setState({ validate: true });
      return;
    }

    emailjs.sendForm(
      'gmail',
      'contact_form',
      '#contact-form',
      'user_WatSHJ5hc24bw8eCCvgMM'
    );

    this.setState({ sent: true });
  };

  renderField(label, name, props) {
    const updateHandler = name => value => {
      this.setState({ [name]: value });
    };

    const inputProps = {
      label,
      name,
      validate: this.state.validate,
      onChange: updateHandler(name),
      ...props
    };

    return (
      <div className="contact__field">
        <Field {...inputProps} />
      </div>
    );
  }

  renderForm() {
    const reasonOptions = [
      { value: 'consult', label: 'Free Consultation' },
      { value: 'collab', label: 'Collaboration' },
      { value: '', label: 'Other' }
    ];

    return (
      <div className="contact__default">
        <h2>Let's Talk!</h2>
        <div>
          Your name and email will be kept confidential and only used to respond
          directly to you.
        </div>
        <form id="contact-form" className="contact__form" autoComplete="nope">
          {this.renderField('Name', 'name')}
          {this.renderField('Email', 'email', { required: true })}
          {this.renderField('Request', 'request', {
            required: true,
            options: reasonOptions
          })}
          {this.renderField('Comment', 'message')}
          <div className="contact__field">
            <Button outline type="submit" onClick={this.sendForm}>
              Send
            </Button>
          </div>
        </form>
        <div className="contact__direct">
          Or, send an email directly to:{' '}
          <Button href="mailto:info@veratti.co">info@veratti.co</Button>
        </div>
      </div>
    );
  }

  renderThankYou() {
    return (
      <div className="contact__thank">
        <h2>Thank you!</h2>
        <div>
          Your message has been sent. A response will find its way to the email
          address you provided soon.
        </div>

        <div className="contact__svg">
          <SendSVG />
        </div>
        <div className="contact__reset">
          Return <AnimLink to="/" text="Home" />
          {' or '}
          <Button secondary onClick={() => this.setState({ sent: false })}>
            Send another message
          </Button>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="contact">
        {this.state.sent ? this.renderThankYou() : this.renderForm()}
      </div>
    );
  }
}
