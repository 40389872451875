import React from 'react';

export default () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg">
    <g transform="translate(-13.688 -31.966)">
      <g transform="matrix(.90423 0 0 .8866 -9.9285 -20.841)" aria-label="V">
        <path d="m61.507 59.561-11.605 36.093h-11.687l-12.097-36.093h12.097l5.8845 19.673q0.60216 1.7452 1.0674 3.4374l6.8972-23.11z" />
      </g>
    </g>
  </svg>
);
