import React, { PureComponent } from 'react';
import './Background.scss';
import V from '../assets/svg/v';

export default class extends PureComponent {
  render() {
    return (
      <div className="background">
        <V />
      </div>
    );
  }
}
