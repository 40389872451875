import React, { Component } from 'react';

class Portfolio extends Component {
  render() {
    return (
      <div>
        <h2>Portfolio</h2>
      </div>
    );
  }
}

export default Portfolio;
