import React from 'react';

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    x="0px"
    y="0px"
    viewBox="0 0 432 432">
    <path
      d="M216,0C140.889,0,80,60.889,80,136v160c0.084,75.076,60.924,135.916,136,136c75.076-0.084,135.916-60.924,136-136V136
			C352,60.889,291.111,0,216,0z M232,96v56c0,8.837-7.163,16-16,16s-16-7.163-16-16V96c0-8.837,7.163-16,16-16S232,87.163,232,96z
			 M335.984,296c0,66.274-53.726,120-120,120s-120-53.726-120-120L96,197.264c16.082,10.356,26.657,27.393,28.8,46.4l6.856,61.76
			c5.16,46.575,47.1,80.149,93.675,74.989c39.463-4.372,70.617-35.526,74.989-74.989l6.864-61.76
			c2.143-19.007,12.718-36.044,28.8-46.4V296z M336,178.992c-24.758,11.87-41.653,35.658-44.704,62.944l-6.864,61.752
			c-4.214,37.794-38.268,65.016-76.062,60.802c-31.987-3.566-57.236-28.815-60.802-60.802l-6.864-61.752
			c-3.051-27.286-19.946-51.074-44.704-62.944V136c0.102-63.104,49.039-115.358,112-119.592v48.728
			C193.915,68.773,184.055,81.453,184,96v56c0.055,14.547,9.915,27.227,24,30.864V232h16v-49.136
			c14.086-3.637,23.945-16.317,24-30.864V96c-0.055-14.547-9.914-27.227-24-30.864V16.408C286.961,20.642,335.898,72.896,336,136
			V178.992z"
    />

    <rect x="208" y="248" width="16" height="16" />
  </svg>
);
