import React, { Component } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { throttle } from 'lodash';
import ReactGA from 'react-ga';
import classnames from 'classnames';

import locations from '../navigation/locations';
import * as pages from '../pages';

import ArrowSVG from '../assets/svg/arrow';

import './Routes.scss';

ReactGA.initialize('UA-122476400-1');
ReactGA.pageview(window.location.pathname);

const initialIndex = locations
  .map(l => l.path)
  .indexOf(window.location.pathname);

class Routes extends Component {
  state = {
    forward: true,
    prevIndex: initialIndex !== -1 ? initialIndex : 0
  };

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyPress, false);

    const { offsetHeight } = this.view;
    this.setState({ offsetHeight });
  }

  componentDidUpdate(prevProps, prevState) {
    const { prevIndex } = this.state;
    const paths = locations.map(l => l.path);
    const nextIndex = paths.indexOf(this.props.location.pathname);

    if (prevIndex !== nextIndex) {
      // Update the container size because these
      // routes are all removed from the document
      // flow through `position: absolute`.
      const { offsetHeight } = this.view;

      const forward = prevIndex < nextIndex;
      this.setState({ forward, prevIndex: nextIndex, offsetHeight });

      ReactGA.pageview(window.location.pathname);
    }
  }

  prevPage = throttle(() => {
    const { history } = this.props;
    let { prevIndex = 0 } = this.state;
    if (prevIndex === 0) prevIndex = locations.length;
    history.push(locations[prevIndex - 1].path);
  }, 500);

  nextPage = throttle(() => {
    const { history } = this.props;
    let { prevIndex = 0 } = this.state;
    if (prevIndex === locations.length - 1) prevIndex = -1;
    history.push(locations[prevIndex + 1].path);
  }, 500);

  onKeyPress = e => {
    switch (e.key) {
      case 'ArrowRight':
        this.nextPage();
        break;
      case 'ArrowLeft':
        this.prevPage();
        break;
    }
  };

  render() {
    const { forward, offsetHeight } = this.state;
    const routeClassNames = classnames('routes', {
      'routes--left': forward,
      'routes--right': !forward
    });

    return (
      <div id="content" className={routeClassNames}>
        {/* Left Arrow */}
        <button
          className="routes__arrow routes__arrow--left"
          title="Previous page"
          onClick={this.prevPage}>
          <ArrowSVG />
        </button>

        {/* Routes */}
        <Route
          render={({ location }) => (
            <TransitionGroup
              className="routes__group"
              style={{ height: offsetHeight }}>
              <CSSTransition
                key={location.key}
                timeout={500}
                classNames="slide">
                <div className="routes__view" ref={ref => (this.view = ref)}>
                  <Switch location={location}>
                    {/* Routing Main Views */}
                    <Route exact path="/" component={pages.Home} />
                    <Route path="/about" component={pages.About} />
                    <Route path="/contact" component={pages.Contact} />
                  </Switch>
                </div>
              </CSSTransition>
            </TransitionGroup>
          )}
        />

        {/* Right Arrow */}
        <button
          className="routes__arrow routes__arrow--right"
          title="Next page"
          onClick={this.nextPage}>
          <ArrowSVG />
        </button>
      </div>
    );
  }
}

export default withRouter(Routes);
