import React from 'react';

export default () => (
  <svg x="0px" y="0px" width="100%" height="100%" viewBox="0 0 480.017 480.017">
    <path d="M208.009,280.017h24v16h-24V280.017z" />
    <path d="M248.009,280.017h24v16h-24V280.017z" />
    <path d="M208.009,344.017h24v16h-24V344.017z" />
    <path d="M248.009,344.017h24v16h-24V344.017z" />
    <path d="M208.009,408.017h24v16h-24V408.017z" />
    <path d="M248.009,408.017h24v16h-24V408.017z" />
    <path
      d="M376.009,96.017c-4.629,0.002-9.252,0.312-13.84,0.928
        C340.611,25.027,264.833-15.798,192.915,5.76C138.073,22.2,99.408,71.237,96.217,128.401c-2.728-0.256-5.472-0.384-8.208-0.384
        c-48.601,0-88,39.399-88,88s39.399,88,88,88h32v104c0.78,10.46,6.801,19.815,16,24.856v39.144c0,4.418,3.582,8,8,8h32
        c2.674,0,5.172-1.335,6.656-3.56l15.576-23.36c27.709,3.893,55.827,3.893,83.536,0l15.576,23.36c1.484,2.225,3.982,3.56,6.656,3.56
        h32c4.418,0,8-3.582,8-8v-39.144c9.199-5.041,15.22-14.396,16-24.856v-104h16c57.438,0,104-46.562,104-104
        S433.446,96.017,376.009,96.017z M208.009,216.017v-30.28c7.512-0.8,15.544-1.352,24-1.6v31.88h16v-31.848
        c8.456,0.216,16.488,0.744,24,1.6v30.248h16v-28c35.128,6.272,56,18.616,56,28c0,13.048-40.512,32-104,32s-104-18.952-104-32
        c0-9.344,20.872-21.688,56-28v28H208.009z M136.497,241.193c1.68,1.128,3.488,2.2,5.368,3.248l1.728,0.928
        c1.856,0.976,3.792,1.92,5.832,2.824l0.8,0.368c2.296,0.992,4.712,1.928,7.2,2.832l2.032,0.712c2.128,0.736,4.312,1.448,6.552,2.12
        l1.488,0.448c2.699,0.784,5.469,1.515,8.312,2.192c0.688,0.168,1.384,0.32,2.08,0.48c2.357,0.533,4.757,1.045,7.2,1.536
        l2.064,0.408c2.976,0.533,5.997,1.04,9.064,1.52l1.896,0.264c2.587,0.368,5.195,0.701,7.824,1c0.8,0.096,1.6,0.192,2.4,0.272
        c3.2,0.336,6.352,0.632,9.552,0.864l1.28,0.08c2.832,0.192,5.68,0.344,8.528,0.456l2.608,0.096c3.248,0.104,6.488,0.176,9.72,0.176
        s6.472-0.072,9.72-0.176l2.608-0.096c2.848-0.112,5.696-0.264,8.528-0.456l1.28-0.08c3.2-0.232,6.4-0.528,9.552-0.864
        c0.8-0.08,1.6-0.176,2.4-0.272c2.632-0.296,5.232-0.624,7.816-0.992l1.904-0.272c3.061-0.453,6.083-0.96,9.064-1.52l2.064-0.408
        c2.437-0.48,4.837-0.992,7.2-1.536c0.696-0.16,1.392-0.312,2.08-0.48c2.837-0.683,5.608-1.413,8.312-2.192l1.488-0.448
        c2.24-0.672,4.424-1.384,6.56-2.12c0.672-0.232,1.352-0.464,2.016-0.712c2.488-0.904,4.904-1.84,7.2-2.832l0.8-0.368
        c2.04-0.904,4-1.848,5.832-2.824l1.728-0.928c1.88-1.048,3.688-2.12,5.368-3.248c0.136-0.096,0.296-0.184,0.44-0.28h0.048v39.104
        c0,13.048-40.512,32-104,32s-104-18.952-104-32v-39.136h0.048C136.201,241.009,136.361,241.097,136.497,241.193L136.497,241.193z
        M136.009,304.881h0.048c0.144,0.096,0.304,0.184,0.44,0.28c1.68,1.128,3.488,2.2,5.368,3.248l1.728,0.928
        c1.856,0.976,3.792,1.92,5.832,2.824l0.8,0.368c2.296,0.992,4.712,1.928,7.2,2.832l2.032,0.712c2.128,0.736,4.312,1.448,6.552,2.12
        l1.488,0.448c2.699,0.784,5.469,1.515,8.312,2.192c0.688,0.168,1.384,0.32,2.08,0.48c2.357,0.533,4.757,1.045,7.2,1.536
        l2.064,0.408c2.976,0.533,5.997,1.04,9.064,1.52l1.896,0.264c2.587,0.368,5.195,0.701,7.824,1c0.8,0.096,1.6,0.192,2.4,0.272
        c3.2,0.336,6.352,0.632,9.552,0.864l1.28,0.08c2.832,0.192,5.68,0.344,8.528,0.456l2.608,0.096c3.248,0.104,6.488,0.176,9.72,0.176
        s6.472-0.072,9.72-0.176l2.608-0.096c2.848-0.112,5.696-0.264,8.528-0.456l1.28-0.08c3.2-0.232,6.4-0.528,9.552-0.864
        c0.8-0.08,1.6-0.176,2.4-0.272c2.632-0.296,5.232-0.624,7.816-0.992l1.904-0.272c3.061-0.453,6.083-0.96,9.064-1.52l2.064-0.408
        c2.437-0.48,4.837-0.992,7.2-1.536c0.696-0.16,1.392-0.312,2.08-0.48c2.837-0.683,5.608-1.413,8.312-2.192l1.488-0.448
        c2.24-0.672,4.424-1.384,6.56-2.12c0.672-0.232,1.352-0.464,2.016-0.712c2.488-0.904,4.904-1.84,7.2-2.832l0.8-0.368
        c2.04-0.904,4-1.848,5.832-2.824l1.728-0.928c1.88-1.048,3.688-2.12,5.368-3.248c0.136-0.096,0.296-0.184,0.44-0.28h0.048v39.136
        c0,13.048-40.512,32-104,32s-104-18.952-104-32L136.009,304.881z M171.729,464.017h-19.72v-22.744
        c9.401,3.782,19.123,6.711,29.048,8.752L171.729,464.017z M328.009,464.017h-19.72l-9.328-13.992
        c9.925-2.041,19.647-4.97,29.048-8.752V464.017z M344.009,408.017c0,13.048-40.512,32-104,32s-104-18.952-104-32v-39.136h0.048
        c0.144,0.096,0.304,0.184,0.44,0.28c1.68,1.128,3.488,2.2,5.368,3.248l1.728,0.928c1.856,0.976,3.792,1.92,5.832,2.824l0.8,0.368
        c2.296,0.992,4.712,1.928,7.2,2.832l2.032,0.712c2.128,0.736,4.312,1.448,6.552,2.12l1.488,0.448
        c2.699,0.784,5.469,1.515,8.312,2.192c0.688,0.168,1.384,0.32,2.08,0.48c2.357,0.533,4.757,1.045,7.2,1.536l2.064,0.408
        c2.976,0.533,5.997,1.04,9.064,1.52l1.896,0.264c2.587,0.368,5.195,0.701,7.824,1c0.8,0.096,1.6,0.192,2.4,0.272
        c3.2,0.336,6.352,0.632,9.552,0.864l1.28,0.08c2.832,0.192,5.68,0.344,8.528,0.456l2.608,0.096c3.248,0.104,6.488,0.176,9.72,0.176
        s6.472-0.072,9.72-0.176l2.608-0.096c2.848-0.112,5.696-0.264,8.528-0.456l1.28-0.08c3.2-0.232,6.4-0.528,9.552-0.864
        c0.8-0.08,1.6-0.176,2.4-0.272c2.632-0.296,5.232-0.624,7.816-0.992l1.904-0.272c3.061-0.453,6.083-0.96,9.064-1.52l2.064-0.408
        c2.437-0.48,4.837-0.992,7.2-1.536c0.696-0.16,1.392-0.312,2.08-0.48c2.837-0.683,5.608-1.413,8.312-2.192l1.488-0.448
        c2.24-0.672,4.424-1.384,6.56-2.12c0.672-0.232,1.352-0.464,2.016-0.712c2.488-0.904,4.904-1.84,7.2-2.832l0.8-0.368
        c2.04-0.904,4-1.848,5.832-2.824l1.728-0.928c1.88-1.048,3.688-2.12,5.368-3.248c0.136-0.096,0.296-0.184,0.44-0.28h0.048
        L344.009,408.017z M376.009,288.017h-16v-72c0-22.496-32.224-37.472-72-44.088v-8.6l13.72-13.712
        c3.202,1.566,6.716,2.386,10.28,2.4c13.255,0,24-10.745,24-24s-10.745-24-24-24s-24,10.745-24,24
        c0.014,3.564,0.834,7.078,2.4,10.28l-16.064,16.064c-1.497,1.501-2.338,3.536-2.336,5.656v9.696c-7.904-0.84-15.952-1.344-24-1.552
        v-68.8l13.72-13.744c3.202,1.566,6.716,2.386,10.28,2.4c13.255,0,24-10.745,24-24s-10.745-24-24-24s-24,10.745-24,24
        c0.014,3.564,0.834,7.078,2.4,10.28l-16.064,16.064c-1.497,1.501-2.338,3.536-2.336,5.656v72.144
        c-8.048,0.208-16.096,0.712-24,1.552v-33.696c0-2.122-0.844-4.156-2.344-5.656l-16.056-16.064c1.566-3.202,2.386-6.716,2.4-10.28
        c0-13.255-10.745-24-24-24s-24,10.745-24,24s10.745,24,24,24l0,0c3.564-0.014,7.078-0.834,10.28-2.4l13.72,13.712v32.6
        c-39.776,6.616-72,21.6-72,44.088v72h-32c-39.765,0-72-32.236-72-72s32.235-72,72-72c4.866,0.003,9.72,0.494,14.488,1.464
        c4.329,0.884,8.555-1.909,9.438-6.238c0.108-0.53,0.162-1.069,0.162-1.61c0-0.456,0-0.92-0.08-1.6
        c-0.026-66.239,53.65-119.957,119.889-119.983c55.448-0.022,103.683,37.969,116.655,91.879c1.008,4.205,5.174,6.848,9.408,5.968
        c47.563-9.992,94.22,20.465,104.213,68.027s-20.465,94.22-68.027,104.213C388.187,287.391,382.106,288.021,376.009,288.017z
        M304.009,128.017c0-4.418,3.582-8,8-8s8,3.582,8,8c0,4.418-3.582,8-8,8S304.009,132.436,304.009,128.017z M264.009,64.017
        c0-4.418,3.582-8,8-8s8,3.582,8,8s-3.582,8-8,8S264.009,68.436,264.009,64.017z M168.009,112.017c-4.418,0-8-3.582-8-8s3.582-8,8-8
        s8,3.582,8,8S172.427,112.017,168.009,112.017z"
    />
  </svg>
);
