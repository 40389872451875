import React from 'react';

export default () => (
  <svg x="0" y="0" height="100%" width="100%" viewBox="0 0 480 480">
    <path d="M232,400h16v16h-16V400z" />
    <path
      d="M472,416h-8V128c0-17.673-14.327-32-32-32h-48V32c0-17.673-14.327-32-32-32H128
				c-17.673,0-32,14.327-32,32v64H48c-17.673,0-32,14.327-32,32v288H8c-4.418,0-8,3.582-8,8v24c0,17.673,14.327,32,32,32h416
				c17.673,0,32-14.327,32-32v-24C480,419.582,476.418,416,472,416z M128,344h224c17.673,0,32-14.327,32-32V144h32v224H64V144h32v168
				C96,329.673,110.327,344,128,344z M112,80h256v232c0,8.837-7.163,16-16,16H128c-8.837,0-16-7.163-16-16V80z M128,16h224
				c8.837,0,16,7.163,16,16v32H112V32C112,23.163,119.163,16,128,16z M32,128c0-8.837,7.163-16,16-16h48v16H56c-4.418,0-8,3.582-8,8
				v240c0,4.418,3.582,8,8,8h368c4.418,0,8-3.582,8-8V136c0-4.418-3.582-8-8-8h-40v-16h48c8.837,0,16,7.163,16,16v288H312
				c-2.12-0.002-4.155,0.839-5.656,2.336L292.688,432H187.312l-13.656-13.664c-1.501-1.497-3.536-2.338-5.656-2.336H32L32,128z
				M464,448c0,8.837-7.163,16-16,16H32c-8.837,0-16-7.163-16-16v-16h148.688l13.656,13.656c1.5,1.5,3.534,2.344,5.656,2.344h112
				c2.122,0,4.156-0.844,5.656-2.344L315.312,432H464V448z"
    />
    <path d="M136,256h208v16H136V256z" />
    <path d="M136,288h192v16H136V288z" />
    <path d="M184,224h160v16H184V224z" />
    <path d="M128,32h16v16h-16V32z" />
    <path d="M160,32h16v16h-16V32z" />
    <path d="M192,32h16v16h-16V32z" />
    <path d="M152,224h16v16h-16V224z" />
    <path
      d="M203.2,182.4l9.6-12.8L189.336,152l23.464-17.6l-9.6-12.8l-32,24
				c-3.535,2.651-4.251,7.665-1.6,11.2c0.455,0.606,0.994,1.145,1.6,1.6L203.2,182.4z"
    />
    <path
      d="M276.8,182.4l32-24c3.535-2.651,4.251-7.665,1.6-11.2c-0.455-0.606-0.994-1.145-1.6-1.6
				l-32-24l-9.6,12.8l23.464,17.6L267.2,169.6L276.8,182.4z"
    />
    <path d="M216.574,189.025l32.007-79.994l14.862,5.947l-32.007,79.994L216.574,189.025z" />
  </svg>
);
