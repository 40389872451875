import React from 'react';

export default () => (
  <svg x="0px" y="0px" width="100%" height="100%" viewBox="0 0 511.995 511.995">
    <path
      d="M401.728,247.995L124.395,2.662C120.021-1.2,113.259-0.837,109.333,3.6c-3.904,4.395-3.477,11.136,0.939,15.061
			l268.309,237.333L110.272,493.328c-4.416,3.925-4.843,10.667-0.939,15.061c2.112,2.389,5.056,3.605,8,3.605
			c2.517,0,5.035-0.896,7.061-2.667l277.333-245.333c2.283-2.027,3.605-4.949,3.605-8
			C405.333,252.944,404.011,250.022,401.728,247.995z"
    />
  </svg>
);
