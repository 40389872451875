import React, { Component } from 'react';

import './Header.scss';
import Navigation from '../navigation/Navigation';

export default class extends Component {
  render() {
    return (
      <header className="header">
        {/* Skip Links */}
        <ul id="skiplinks">
          <li>
            <a href="#navigation">To Navigation</a>
          </li>
          <li>
            <a href="#content">To Content</a>
          </li>
        </ul>
        <h1 className="header__name">
          <span className="fancy">Veratti</span> Web Development
        </h1>
        <Navigation />
      </header>
    );
  }
}
